import React, { ChangeEvent } from 'react';
import { Model, ModelProperty, ModelReference } from '../../redux/model/types';
import { useAppSelector } from '../../redux/hooks';
import { selectModelDefinitionById, selectModelByDefinitionId as selectModelsByDefinitionId } from '../../redux/model/modelSlice';
import { Input, InputAdornment, IconButton, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';
import { Search } from '@mui/icons-material';
import TitleBox from '../widgets/TitleBox';
import ModelDataGrid from './ModelDataGrid';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import ModelSelectionDataGrid from './ModelSelectionDataGrid';
import ModelSelectionDialog from './ModelSelectionDialog';
import Clear from '@mui/icons-material/Clear';

interface Props {
    modelProperty: ModelProperty,
    handleEntryDataChange: (id: number, value: any) => void,
}

export default function ModelSelectionInput(props: Props) {
    const { modelProperty, handleEntryDataChange } = props;

    const [open, setOpen] = React.useState(false);
    const [searchQuery, setSearchQuery] = React.useState("");
    const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([]);

    const models = useAppSelector(selectModelsByDefinitionId(modelProperty.modelDefinitionId));
    const definition = useAppSelector(selectModelDefinitionById(modelProperty.modelDefinitionId));

    const filteredModels = modelProperty.referencedValue !== null
        ? models.filter(x => x.id !== (modelProperty.referencedValue as ModelReference).modelId)
        : models;

    const model = modelProperty.referencedValue !== null
        ? (modelProperty.referencedValue as Model)?.modelName
        : "";

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const handleSubmit = () => {
        if (selectedIds.length) {
            handleEntryDataChange(modelProperty.id, { modelId: selectedIds[0] });
        }

        setOpen(false);
    };

    return (
        <div>
            <TextField
                sx={{ width: '250px' }}
                variant="filled"
                id={`${modelProperty.name}-${modelProperty.id}`}
                label={modelProperty.name}
                type="text"
                placeholder="Type something..."
                value={model}
                InputProps={{
                    endAdornment: (
                        <>
                            {
                                modelProperty.referencedValue !== null && (
                                    < InputAdornment position="end">
                                        <IconButton onClick={handleOpen}>
                                            <Clear />
                                        </IconButton>
                                    </InputAdornment>)
                            }
                            <InputAdornment position="end">
                                <IconButton onClick={handleOpen}>
                                    <Search />
                                </IconButton>
                            </InputAdornment>
                        </>
                    ),
                }}
            />
            < ModelSelectionDialog
                definition={definition}
                searchQuery={searchQuery}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                handleSearchChange={handleSearchChange}
                open={open} >
                <ModelSelectionDataGrid
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    definition={definition}
                    models={filteredModels} />
            </ModelSelectionDialog >
        </div >
    );
}