export enum ModelPropertyType {
    string = "String",
    number = "Number",
    boolean = "Boolean",
    stringArray = "StringArray",
    numberArray = "NumberArray",
    model = "Model",
    modelArray = "ModelArray"
}

export enum ModelType {
    static = "Static",
    dynamic = "Dynamic",
}

export interface Model {
    id: number,
    properties: ModelProperty[]
    modelName: string,
    modelType: ModelType,
    modelDefinitionId?: number
    projectId?: number
}

export interface ModelProperty {
    id: number,
    name: string,
    isDisplayed: boolean,
    type: ModelPropertyType,
    referencedValue: any,
    modelDefinitionId: number,
    propertyInfoId: number,
}

export interface ModelPropertyInfo {
    id: number,
    name: string,
    type: ModelPropertyType,
    isDisplayed: boolean,
    modelDefinitionId?: number,
    propertyModelDefinitionId?: number,
}

export interface ModelDefinition {
    id: number,
    name: string,
    properties: ModelPropertyInfo[]
    type: ModelType,
    projectId?: number,
    version: string
}

export interface ModelReference {
    modelId: number,
}