import React from 'react';
import useRouteParam from '../../hooks/useRouteParam';
import { defaultModel, defaultModelDefinition, defaultPropertyInfo, getModelDefinitions, getModels, selectModelById, selectModelDefinitionById, selectModelDefinitions, selectModels, updateModel, updateModelDefinition } from '../../redux/model/modelSlice';
import { Autocomplete, Button, FilledInput, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, TextField, useTheme } from '@mui/material';
import { BackendError, useAppDispatch, useAppSelector } from '../../redux/hooks';
import TitleBox from '../widgets/TitleBox';
import { AccountTree, Add, Cancel, CheckBox, CheckBoxOutlineBlank, CheckBoxOutlined, Delete, DeleteOutlined, Edit, LanguageOutlined, Save } from '@mui/icons-material';
import { GridRowSelectionModel, GridRowModesModel, GridRowParams, MuiEvent, GridEventListener, GridRowId, GridRowModes, GridRowModel, GridColDef, GridActionsCellItem, DataGrid, GridRenderCellParams, GridRenderEditCellParams } from '@mui/x-data-grid';
import SnackBarOperations from '../../components/SnackBar/SnackBarOperations';
import { DynProps, DynDataType } from '../../redux/dyndata/types';
import yup from '../../validation/yup';
import ConfirmationPopover from '../utils/ConfirmationPopover';
import DeleteConfirmationDialog from '../utils/DeleteConfirmationDialog';
import StyledDataGrid from '../utils/StyledDataGrid';
import { ModelDefinition, ModelPropertyInfo, ModelPropertyType, ModelType } from '../../redux/model/types';
import { debounce } from 'lodash';

export default function ProjectModelDefinition() {

    const definitionId = useRouteParam('definitionId');

    const projectId = useRouteParam('projectId');

    const theme = useTheme();
    const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([]);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [editModelDefinition, setEditModelDefinition] = React.useState(defaultModelDefinition);

    const projectModelDefinitions = useAppSelector(selectModelDefinitions);

    const currentModelDefinition = useAppSelector(selectModelDefinitionById(definitionId));
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (currentModelDefinition)
            setEditModelDefinition({ ...currentModelDefinition });
    }, [currentModelDefinition])

    React.useEffect(() => {
        dispatch(getModelDefinitions(projectId));
    }, [])

    const handleRowEditStart = React.useCallback((
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    }, []);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = React.useCallback((params, event) => {
        event.defaultMuiPrevented = true;
    }, []);

    const handleEditClick = React.useCallback((id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    }, []);

    const handleSaveClick = React.useCallback((id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    }, []);

    const handleDeleteClick = React.useCallback((id: GridRowId) => () => {
        const changedModelDefinition = { ...editModelDefinition, properties: [...editModelDefinition.properties.filter(x => x.id !== id)] }
        dispatch(updateModelDefinition(changedModelDefinition));
    }, [dispatch, editModelDefinition]);

    const handleCancelClick = React.useCallback((id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    }, [setRowModesModel, rowModesModel]);

    const getPropertyValidationSchema = React.useCallback((id: number) => yup.object().shape({
        name: yup.string().required('Property tag name is required').unique('Property tag name must be unique', (val: string) => {
            return !currentModelDefinition.properties.filter(x => x.id !== id).map(x => x.name).includes(val);
        })
    }), [currentModelDefinition])

    const processRowUpdate = React.useCallback(async (newRow: GridRowModel, oldRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };

        try {
            await getPropertyValidationSchema(newRow.id).validate(updatedRow);
            const propertyInfo = newRow as ModelPropertyInfo;
            const changedModel: ModelDefinition = { ...currentModelDefinition, properties: [...currentModelDefinition.properties.filter(x => x.id !== propertyInfo.id), propertyInfo] }
            await dispatch(updateModelDefinition(changedModel));
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                err.errors.forEach((strErr) => SnackBarOperations.error(strErr));
                throw err;
            }
        }
        return updatedRow;
    }, [dispatch, getPropertyValidationSchema]);

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'id',
            hideable: false
        },
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 100,
            flex: 1,
            editable: true
        },
        {
            field: 'type',
            headerName: 'Type',
            minWidth: 100,
            flex: 1,
            type: 'singleSelect',
            getOptionValue: (value: any) => value.code,
            getOptionLabel: (value: any) => value.name,
            valueOptions: [
                { code: ModelPropertyType.string, name: 'string' },
                { code: ModelPropertyType.number, name: 'number' },
                { code: ModelPropertyType.boolean, name: 'boolean' },
                { code: ModelPropertyType.model, name: 'model' },
                { code: ModelPropertyType.stringArray, name: 'List of texts' },
                { code: ModelPropertyType.numberArray, name: 'List of numbers' },
                { code: ModelPropertyType.modelArray, name: 'List of models' },
            ],
            editable: true
        },
        {
            field: 'propertyModelDefinitionId',
            headerName: 'Definition',
            flex: 0.5,
            editable: true,
            renderCell: (params: GridRenderCellParams<ModelPropertyInfo, number>) => {
                if (params.row.type !== ModelPropertyType.model && params.row.type !== ModelPropertyType.modelArray)
                    return <></>
                const definition = projectModelDefinitions.find(x => x.id === params.value);
                return definition?.name || 'Select';
            },
            renderEditCell: (params: GridRenderEditCellParams) => {
                if (params.row.type !== ModelPropertyType.model && params.row.type !== ModelPropertyType.modelArray)
                    return <></>

                const selectedDefinition = projectModelDefinitions.find((option) => option.id === params.value) || null;

                return (
                    <Autocomplete
                        value={selectedDefinition}
                        options={projectModelDefinitions}
                        getOptionLabel={(option) => option.name} 
                        onChange={(event, newValue) => {
                            params.api.setEditCellValue({ id: params.id, field: 'propertyModelDefinitionId', value: newValue?.id });
                        }}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                        fullWidth
                    />
                );
            },
        },
        {
            field: "isDisplayed",
            headerName: "Displayed",
            minWidth: 100,
            type: 'boolean',
            editable: true,
            renderCell(params) {
                return params.value ? <CheckBox/> : <CheckBoxOutlineBlank/>;
            },
        },
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Actions',
            getActions: ({ id, row }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            key="save"
                            icon={<Save />}
                            label="Save"
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            key="cancel"
                            icon={<Cancel />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        key="edit"
                        icon={<Edit />}
                        label="Edit"
                        disabled={row.isDefault}
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <DeleteConfirmationDialog onDeleteConfirm={handleDeleteClick(id)}>
                        <GridActionsCellItem
                            key="delete"
                            icon={<Delete />}
                            label="Delete"
                            disabled={row.isDefault}
                            color="inherit"
                        />
                    </DeleteConfirmationDialog>,
                ];
            },
        }
    ];

    const handleSelectedPropertiesDelete = React.useCallback(() => {
        const changedModelDefinition = { ...editModelDefinition, properties: [...editModelDefinition.properties.filter(x => selectedIds.includes(x.id))] }
        dispatch(updateModelDefinition(changedModelDefinition));
    }, [editModelDefinition, selectedIds, dispatch]);

    const getModelValidationSchema = React.useCallback((id: number) => yup.object().shape({
        name: yup.string().required('Model definition name is required.').unique('Model with that name already exists in this project.', (name: string) => !projectModelDefinitions.filter(x => x.id != id).map(x => x.name).includes(name))
    }), [projectModelDefinitions]);

    const handleModelDefinitionSave = React.useCallback(async (changedModelDefinition: ModelDefinition) => {
        try {
            await getModelValidationSchema(changedModelDefinition.id).validate(changedModelDefinition);
            await dispatch(updateModelDefinition(changedModelDefinition));
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                SnackBarOperations.error(err.message);
            } if (err instanceof BackendError) {
                SnackBarOperations.error(`Error saving model: ${err.message}`);
            }
            console.error(err);
        }
    }, [dispatch, getModelValidationSchema]);

    const debouncedChangeHandler = React.useCallback(
        debounce(handleModelDefinitionSave, 500),
        [handleModelDefinitionSave],
    );

    const handleModelDefinitionChange = React.useCallback((modelDefinition: ModelDefinition) => {
        setEditModelDefinition(modelDefinition);
        debouncedChangeHandler(modelDefinition);
    }, [debouncedChangeHandler, setEditModelDefinition]);

    const handlePropertyAdd = React.useCallback(() => {
        let changedModel = { ...editModelDefinition };
        changedModel.properties = [...changedModel.properties, { ...defaultPropertyInfo, modelDefinitionId: changedModel.id }];
        dispatch(updateModelDefinition(changedModel));
    }, [editModelDefinition, dispatch]);

    return (
        <>
            <TitleBox
                avatarIcon={<LanguageOutlined />}
                mainTitle='Edit model definition'
                subTitle='Lorem ipsum'>
            </TitleBox>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ width: '100%', padding: theme.spacing(2) }}>
                        <TextField sx={{ width: '250px', marginRight: theme.spacing(1) }} id="model-definition-name" label="Name" variant="filled" value={editModelDefinition.name} onChange={(e) => handleModelDefinitionChange({ ...editModelDefinition, name: e.target.value })} />
                        <FormControl variant="filled" sx={{ width: '250px' }}>
                            <InputLabel id="model-label">Model definition</InputLabel>
                            <Select
                                id='model-type-select'
                                labelId="model-type-label"
                                value={editModelDefinition.type}
                                input={<FilledInput />}
                                onChange={(e) => handleModelDefinitionChange({ ...editModelDefinition, type: e.target.value as ModelType })}
                            >
                                {Object.values(ModelType).map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{ height: '700px', width: '100%', padding: theme.spacing(2), display: 'flex', flexDirection: 'column' }}>
                        <TitleBox
                            mainTitle='Properties'
                            subTitle='Lorem ipsum'
                            maintitleVariant='h6'
                            subTitleVariant='subtitle2'>
                            <DeleteConfirmationDialog onDeleteConfirm={handleSelectedPropertiesDelete}>
                                <IconButton component="label" disabled={selectedIds.length < 1}>
                                    <DeleteOutlined />
                                </IconButton>
                            </DeleteConfirmationDialog>
                            <Button variant="contained" onClick={handlePropertyAdd} sx={{ marginLeft: theme.spacing(1) }} endIcon={<Add />}>
                                ADD PROPERTY
                            </Button>
                        </TitleBox>
                        <DataGrid
                            sx={{ flex: 1 }}
                            rows={currentModelDefinition.properties}
                            columns={columns}
                            pageSizeOptions={[10]}
                            checkboxSelection
                            onRowSelectionModelChange={(newSM) => {
                                setSelectedIds(newSM);
                            }}
                            editMode='row'
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={setRowModesModel}
                            onRowEditStart={handleRowEditStart}
                            onRowEditStop={handleRowEditStop}
                            processRowUpdate={processRowUpdate}
                            rowSelectionModel={selectedIds}
                            disableRowSelectionOnClick
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'id', sort: 'asc' }], // Sort by ID
                                },
                                columns: {
                                    columnVisibilityModel: {
                                        id: false, // Hide the ID column
                                    },
                                },
                            }}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}