import { createSlice, isRejected } from "@reduxjs/toolkit";
import axios from "axios";
import { createAppAsyncThunk } from "../hooks";
import { RootState } from "../store";
import { Model, ModelDefinition, ModelPropertyInfo, ModelPropertyType, ModelType } from "./types";

const PREFIX = "page";

const prefix = (str: string) => `${PREFIX}/${str}`;

export interface ModelState {
  models: Model[];
  modelDefinitions: ModelDefinition[];
}

const initialState: ModelState = {
  models: [],
  modelDefinitions: []
};

export const defaultModel: Model = {
  id: 0,
  modelName: 'new Model',
  modelType: ModelType.dynamic,
  properties: []
};

export const defaultModelDefinition: ModelDefinition = {
  id: 0,
  name: 'new Definition',
  type: ModelType.dynamic,
  properties: [],
  version: ''
};

export const defaultPropertyInfo: ModelPropertyInfo = {
  id: 0,
  name: 'new Property',
  isDisplayed: true,
  type: ModelPropertyType.string,
};

export const getModels = createAppAsyncThunk(
  prefix("getModel"),
  async (projectId: number) => {
    const response = await axios.get<Model[]>(`/model/${projectId}`);
    return response.data;
  }
);

export const createModel = createAppAsyncThunk(
  prefix("createModel"),
  async function (model: Model) {
    const response = await axios.post<Model>("/model", model);
    return response.data;
  }
);

export const updateModel = createAppAsyncThunk(
  prefix("updateModel"),
  async (model: Model) => {
    const response = await axios.post<Model>("/model/update", model);
    return response.data;
  }
);

export const deleteModel = createAppAsyncThunk(
  prefix("deleteModel"),
  async (id: number) => {
    await axios.delete(`/model/${id}`);
    return id;
  }
);

export const getModelDefinitions = createAppAsyncThunk(
  prefix("getModelDefintions"),
  async (projectId: number) => {
    const response = await axios.get<ModelDefinition[]>(`/model/definitions/${projectId}`);
    return response.data;
  }
);

export const createModelDefinition = createAppAsyncThunk(
  prefix("createModelDefintion"),
  async function (modelDefinition: ModelDefinition) {
    const response = await axios.post<ModelDefinition>("/model/definitions", modelDefinition);
    return response.data;
  }
);

export const updateModelDefinition = createAppAsyncThunk(
  prefix("updateModelDefintion"),
  async (modelDefinition: ModelDefinition) => {
    const response = await axios.post<ModelDefinition>("/model/definitions/update", modelDefinition);
    return response.data;
  }
);

export const deleteModelDefinition = createAppAsyncThunk(
  prefix("deleteModelDefintion"),
  async (id: number) => {
    await axios.delete(`/model/definitions/${id}`);
    return id;
  }
);

const modelSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // base cases
    builder.addCase(getModels.fulfilled, (state, action) => {
      state.models = action.payload;
    });

    builder.addCase(createModel.fulfilled, (state, action) => {
      state.models.push(action.payload);
    });

    builder.addCase(updateModel.fulfilled, (state, action) => {
      const index = state.models.findIndex((x) => x.id === action.payload.id);
      state.models[index] = action.payload;
    });

    builder.addCase(deleteModel.fulfilled, (state, action) => {
      state.models = state.models.filter((x) => x.id !== action.payload);
    });

    builder.addCase(getModelDefinitions.fulfilled, (state, action) => {
      state.modelDefinitions = action.payload;
    });

    builder.addCase(createModelDefinition.fulfilled, (state, action) => {
      state.modelDefinitions.push(action.payload);
    });

    builder.addCase(updateModelDefinition.fulfilled, (state, action) => {
      const index = state.modelDefinitions.findIndex((x) => x.id === action.payload.id);
      state.modelDefinitions[index] = action.payload;
    });

    builder.addCase(deleteModelDefinition.fulfilled, (state, action) => {
      state.modelDefinitions = state.modelDefinitions.filter((x) => x.id !== action.payload);
    });
    // logs error if a request is rejected
    builder.addMatcher(isRejected, (_, action) => {
      console.error(action.error.message);
    });
  },
});

export const selectModels = (rootState: RootState) =>
  rootState.models.models;

export const selectModelById = (id: number) => (rootState: RootState) =>
  rootState.models.models.find(x => x.id === id) ?? defaultModel;

export const selectModelByDefinitionId = (id: number) => (rootState: RootState) =>
  rootState.models.models.filter(x => x.modelDefinitionId === id);

export const selectModelDefinitions = (rootState: RootState) =>
  rootState.models.modelDefinitions;

export const selectModelDefinitionById = (id: number) => (rootState: RootState) =>
  rootState.models.modelDefinitions.find(x => x.id === id) ?? defaultModelDefinition;

export default modelSlice.reducer;
