import { IconButton, Paper, useTheme, Button } from "@mui/material";
import React from "react";
import TitleBox from "../widgets/TitleBox";
import ViewQuiltOutlinedIcon from "@mui/icons-material/ViewQuiltOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { DataGrid, GridActionsCellItem, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import useRouteParam from "../../hooks/useRouteParam";
import { Edit, Delete, ViewCompact, CheckBox, CheckBoxOutlined } from "@mui/icons-material";
import DeleteConfirmationDialog from "../utils/DeleteConfirmationDialog";
import { createModel, createModelDefinition, defaultModel, defaultModelDefinition, deleteModelDefinition, getModelDefinitions, getModels, selectModelDefinitions, selectModels } from "../../redux/model/modelSlice";
import { deleteModel } from "../../redux/dyndata/dyndataSlice";
import { ModelDefinition } from "../../redux/model/types";


interface Props {
    modelDefinitions: ModelDefinition[],
    selectedIds: GridRowSelectionModel,
    setSelectedIds: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>,
}


export default function ModelDefinitionDataGrid(props: Props) {
    const { modelDefinitions, selectedIds, setSelectedIds } = props;
    const projectId = useRouteParam("projectId");

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "Name",
            flex: 1,
        },
        {
            field: "modelType",
            headerName: "Type",
            minWidth: 100,
            flex: 1,
            renderCell(params) {
                return params.value;
            },
        },
        {
            field: 'actions',
            type: 'actions',
            minWidth: 120,
            cellClassName: 'actions',
            headerName: 'Actions',
            getActions: ({ id, row }) => {
                return [
                    <GridActionsCellItem
                        key="view"

                        icon={<ViewCompact />}
                        label="View"
                        disabled={row.isDefault}
                        className="textPrimary"
                        onClick={() => navigate(`/project-detail/${projectId}/models/${id}`)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        key="edit"

                        icon={<Edit />}
                        label="Edit"
                        disabled={row.isDefault}
                        className="textPrimary"
                        onClick={() => navigate(`/project-detail/${projectId}/definition/${id}`)}
                        color="inherit"
                    />,
                    <DeleteConfirmationDialog onDeleteConfirm={() => dispatch(deleteModelDefinition(Number(id)))}>
                        <GridActionsCellItem
                            key="delete"
                            icon={<Delete />}
                            label="Delete"
                            disabled={row.isDefault}
                            color="inherit"
                        />
                    </DeleteConfirmationDialog>,
                ];
            },
        }
    ];

    return (
        <DataGrid
            rows={modelDefinitions}
            columns={columns}
            pageSizeOptions={[10]}
            checkboxSelection
            onRowSelectionModelChange={(newSM) => {
                setSelectedIds(newSM);
            }}
            rowSelectionModel={selectedIds}
            disableRowSelectionOnClick
        />
    );
}
