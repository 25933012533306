import { LanguageOutlined, CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";
import { Checkbox, FormControlLabel, Paper, Stack, TextField, useTheme } from "@mui/material";
import React from "react";
import useRouteParam from "../../hooks/useRouteParam";
import { useAppSelector, useAppDispatch, BackendError } from "../../redux/hooks";
import yup from "../../validation/yup";
import TitleBox from "../widgets/TitleBox";
import { defaultModel, getModelDefinitions, getModels, selectModelById, updateModel } from "../../redux/model/modelSlice";
import { Model, ModelPropertyType } from "../../redux/model/types";
import ModelSelectionInput from "./ModelSelectionInput";
import ModelsSelectionList from "./ModelsSelectionList";
import { debounce } from "lodash";

export default function ProjectModel() {

    const modelId = useRouteParam('modelId');
    const projectId = useRouteParam('projectId');
    const theme = useTheme();
    const [editModel, setEditModel] = React.useState(defaultModel);
    const model = useAppSelector(selectModelById(modelId));
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (model)
            setEditModel({ ...model });
    }, [model])

    React.useEffect(() => {
        dispatch(getModelDefinitions(projectId));
        dispatch(getModels(projectId));
    }, [])

    const handleEntryDataChange = React.useCallback((id: number, value: any) => {
        let changedModel = {
            ...editModel,
            properties: editModel.properties.map(prop =>
                prop.id === id ? { ...prop } : prop
            )
        };
        let changedPropIndex = changedModel.properties.findIndex(x => x.id === id);

        if (changedPropIndex < 0)
            return;

        if (changedModel.properties[changedPropIndex].type == ModelPropertyType.number) {
            const validPattern = /^-?(?:\d+\.)?\d*$|^-?\.\d+$/;
            if (value !== "" && !validPattern.test(value)) return;
        }

        changedModel.properties[changedPropIndex].referencedValue = value;

        setEditModel(changedModel);
        debouncedChangeHandler(changedModel);
    }, [setEditModel, editModel]);

    const handleModelSave = React.useCallback(async (changedModel: Model) => {
        await dispatch(updateModel(changedModel));
    }, [dispatch]);

    const debouncedChangeHandler = React.useCallback(
        debounce(handleModelSave, 500),
        [handleModelSave],
    );

    return (
        <>
            <TitleBox
                avatarIcon={<LanguageOutlined />}
                mainTitle='Edit entry'
                subTitle='Lorem ipsum'>
            </TitleBox>
            <Paper sx={{ width: '100%', padding: theme.spacing(2) }}>
                <Stack spacing={1}>
                    {
                        editModel.properties.map((prop) => {
                            switch (prop.type) {
                                case ModelPropertyType.boolean:
                                    return (
                                        <FormControlLabel
                                            value="end"
                                            control={
                                                <Checkbox
                                                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                                                    checkedIcon={<CheckBox fontSize="small" />}
                                                    color="primary"
                                                    checked={prop.referencedValue}
                                                    onChange={(e, checked) => handleEntryDataChange(prop.id, checked)} />}
                                            label={prop.name}
                                            key={prop.id}
                                            labelPlacement="end"
                                        />
                                    );
                                case ModelPropertyType.number:
                                    return (
                                        <TextField
                                            sx={{ width: '250px' }}
                                            variant="filled"
                                            id={`${prop.name}-${modelId}`}
                                            label={prop.name}
                                            key={prop.id}
                                            value={prop.referencedValue}
                                            onChange={(e) => handleEntryDataChange(prop.id, e.target.value)} />
                                    );
                                case ModelPropertyType.model:
                                    return (
                                        <ModelSelectionInput modelProperty={prop} handleEntryDataChange={handleEntryDataChange} key={prop.id} />
                                    );
                                case ModelPropertyType.modelArray:
                                    return (
                                        <ModelsSelectionList modelProperty={prop} handleEntryDataChange={handleEntryDataChange} key={prop.id} />
                                    );
                                default:
                                    return (
                                        <TextField
                                            sx={{ width: '250px' }}
                                            id={`${prop.name}-${modelId}`}
                                            label={prop.name}
                                            key={prop.id}
                                            variant="filled"
                                            value={prop.referencedValue}
                                            onChange={(e) => handleEntryDataChange(prop.id, e.target.value)} />
                                    );
                            }
                        })
                    }
                </Stack>
            </Paper>
        </>
    );
}